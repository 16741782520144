<template>
  <div>
    <div class="titre-container">
      <h1 class="mt-0 fs-b fs-2500 color-pr">Paiement de la dette</h1>
    </div>
    <div class="text-left">
      <a
        id="pai-02"
        href="#"
        @click="goPrevious($event)"
        class="fr-link p-0 color-pr fs-875 a-info"
        title="Retour au détail paiement"
        ><i class="ri-arrow-left-line ml-1"></i>Retour au détail
      </a>
    </div>

    <ErreurPaiementImpot
      v-if="erreur"
      :titre="titreErreurMessage"
      :message="erreurMessage"
    />
    <div v-if="!erreur && dataPaiementAmr">
      <div class="text-center mt-30">
        <Stepper id="pai-03" ref="stepper" :dataSteps="dataSteps" />
      </div>
      <div class="mt-40">
        <div :class="conteneurSyntheseClasses">
          <div
            class="fr-col-4"
            v-if="!affichageSelectionCompte && !affichagePriseEnCompte"
          >
            <Synthese
              id="pai-04"
              :dataSynthese="dataPaiementAmr"
              :isAmr="true"
            />
          </div>
          <!-- Créances AMR -->
          <div v-if="affichageMontantAPayer" class="fr-col-8">
            <div class="ml-32">
              <div class="d-flex">
                <span
                  class="ri-money-euro-circle-line bleu-fr-400 mt--7"
                ></span>
                <h2 class="title-bleu-fr bleu-fr-400">
                  Créances associées à l'AMR
                </h2>
              </div>
              <div v-if="dataPaiementAmr.listeCreanceAmr" class="item-txt">
                Vous pouvez répartir le montant que vous souhaitez régler sur
                les créances ci-dessous
              </div>
              <AlertDesignSystem
                v-if="showErrorRepMontants && affichageRepartitionMontant"
                class="alert w-100 mt-20"
                :titre="erreurTitleRepMontants"
                :message="erreurMessageRep"
                type="error"
              />
              <div
                class="fr-grid-row mt-3"
                v-for="(item, dindex) in dataPaiementAmr.listeCreanceAmr"
                :key="`creance-${dindex}`"
              >
                <div class="fr-col-7">
                  <div>
                    <div class="libelle">Période</div>
                    <div class="item">{{ item.periode }}</div>
                  </div>
                  <div class="mt-3">
                    <div class="libelle">Elément à payer</div>
                    <div class="item">
                      {{ item.detailElementAPayer }}
                    </div>
                  </div>
                  <div class="mt-3">
                    <div class="libelle">Montant restant à payer</div>
                    <span class="sr-only">
                      {{
                        $filters.numberSpacedRgaa(
                          item.creanceResteAPayerApresValidationOrdreAmr
                        )
                      }}</span
                    >
                    <span aria-hidden="true" class="item">{{
                      $filters.numberSpaced(
                        item.creanceResteAPayerApresValidationOrdreAmr
                      )
                    }}</span>
                  </div>
                </div>
                <div class="fr-col-1">
                  <div class="float-r mr-30">
                    <div class="oval"></div>
                    <div class="line"></div>
                    <div class="oval"></div>
                    <div class="line"></div>
                    <div class="oval"></div>
                  </div>
                </div>
                <div class="fr-col-4">
                  <div>
                    <div class="libelle">Ce que je dois</div>
                    <span class="sr-only">
                      {{ $filters.numberSpacedRgaa(item.soldeDuCreance) }}
                    </span>
                    <span class="item base-color fw-b" aria-hidden="true">
                      {{ $filters.numberSpaced(item.soldeDuCreance) }}
                    </span>
                  </div>
                  <div class="mt-3">
                    <Amount
                      :id="'pai-05-' + dindex"
                      libelle="Ce que je souhaite payer"
                      :initValeur="
                        item.soldeDuCreance > 0
                          ? item.soldeDuCreance.toString()
                          : '0'
                      "
                      :valeur="
                        item.montantVentilationPaiementCreance > 0
                          ? item.montantVentilationPaiementCreance.toString()
                          : '0'
                      "
                      :actif="item.soldeDuCreance > 0"
                      :compSupVal="compSupVal"
                      :messageCompSupVal="messageCompSupVal"
                      :idMontant="'resteAPayerCreance-' + dindex"
                      :firstInit="true"
                      :item="item"
                    />
                  </div>
                  <div class="mt-3">
                    <div class="libelle">
                      Ce qu'il me restera à payer ultérieurement
                    </div>
                    <span class="sr-only">
                      {{ $filters.numberSpacedRgaa(item.resteApayer) }}</span
                    >
                    <span :key="testIndex" aria-hidden="true" class="fw-bold">{{
                      $filters.numberSpaced(item.resteApayer)
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-50 float-r">
              <button
                ref="poursuivre"
                id="pai-06"
                class="fr-btn pr-btn btn-action"
                title="Poursuivre"
                aria-label="Poursuivre"
                @click="poursuivre()"
                :disabled="disableResteAPayerCreance"
              >
                <span class="pointer">Poursuivre</span>
              </button>
            </div>
          </div>
          <div v-if="affichageSelectionCompte" class="fr-col-12">
            <h2 class="fw-bold ml-20">
              Sélectionnez entre 1 et 3 comptes bancaires
            </h2>
            <AlertDesignSystem
              v-if="showErrorMultiCompte"
              class="alert ml-20 w-100 mt-20"
              :titre="erreurTitleCompte"
              :message="erreurMessageCompte"
              type="min-error"
            />
            <div class="fr-grid-row mt-4">
              <div
                v-for="(item, index) in dataPaiementAmr.listeMandatsB2BActif"
                :key="index"
                class="wrapper-cb mr-2_1 mb-1875"
              >
                <div class="fr-toggle float-r">
                  <input
                    type="checkbox"
                    class="fr-toggle__input"
                    :id="'switcher' + index"
                    v-model="item.compteEnabled"
                    @click="switchSelectCompte(item)"
                  />
                  <label
                    class="fr-toggle__label"
                    :for="'switcher' + index"
                  ></label>
                </div>
                <div class="mt-375">
                  <div class="libelle">Numéro de compte</div>
                  <div class="item">{{ item.iban }}</div>
                </div>
                <div class="mt-1">
                  <div class="libelle">Information complémentaire</div>
                  <div class="item">
                    {{ item.indicationsUtilisationCompte }}
                  </div>
                </div>
                <div class="mt-3">
                  <div class="libelle">&#201;tablissement</div>
                  <span class="item">{{ item.banque }}</span>
                </div>
                <div class="mt-3">
                  <div class="libelle">BIC</div>
                  <span class="item">{{ item.bic }}</span>
                </div>
                <div class="mt-3">
                  <div class="libelle">Titulaire</div>
                  <span class="item">{{ item.titulaire }}</span>
                </div>
              </div>
            </div>
            <div class="mt-50 float-r d-inline-flex">
              <button
                id="pai-10"
                class="fr-btn fr-btn--secondary mr-20 prs-btn"
                title="Précédent"
                aria-label="Précédent"
                @click="previous()"
              >
                <span class="pointer td-previous">Précédent</span>
              </button>
              <button
                id="pai-11"
                class="fr-btn pr-btn btn-action btn-confirmation w-210"
                title="Confirmer la sélection"
                aria-label="Confirmer la sélection"
                @click="handleSelectionMultipleCompte()"
                :disabled="disabledConfirmation"
              >
                <span class="pointer">Confirmer la sélection</span>
              </button>
            </div>
          </div>
          <div
            v-if="affichageRecapitulatif || affichageRepartitionMontant"
            class="fr-col-7"
          >
            <div class="ml-32">
              <div class="d-flex">
                <span class="ri-bank-fill bleu-fr-400 mt--7"></span>
                <!-- Cas mono/multi mandat(s) -->
                <h2 class="title-bleu-fr bleu-fr-400">
                  {{
                    dataPaiementAmr.listeMandatsB2BActif.length > 1
                      ? 'Mandats bancaires'
                      : 'Mandat bancaire'
                  }}
                </h2>
              </div>
              <div
                v-if="dataPaiementAmr.listeMandatsB2BActif.length > 1"
                class="item-txt"
              >
                Veuillez répartir votre paiement entre tous les comptes
                bancaires ci-dessous
              </div>
              <AlertDesignSystem
                v-if="showErrorRepMontants && affichageRepartitionMontant"
                class="alert w-100 mt-20"
                :titre="erreurTitleRepMontants"
                :message="erreurMessageRep"
                type="error"
              />
              <div
                class="wrapper-fr"
                v-for="(item,
                dindex) in dataPaiementAmr.listeMandatsB2BActifSelected"
                :key="`mandat-${dindex}`"
              >
                <div class="fr-grid-row">
                  <div class="fr-col-8">
                    <div>
                      <div class="libelle">Numéro de compte</div>
                      <div class="item">{{ item.iban }}</div>
                    </div>
                    <div class="mt-3">
                      <div class="libelle">Information complémentaire</div>
                      <div class="item">
                        {{ item.indicationsUtilisationCompte }}
                      </div>
                    </div>
                  </div>
                  <div class="fr-col-4">
                    <div>
                      <div class="libelle">
                        &#201;tablissement<Info
                          href="#"
                          :data="
                            'BIC : ' +
                            item.bic +
                            '<br>Titulaire : ' +
                            item.titulaire
                          "
                          class="fr-fi-information-line info a-info float-r"
                        />
                      </div>
                      <div class="item">{{ item.banque }}</div>
                    </div>
                    <div class="mt-3">
                      <div v-if="affichageRecapitulatif" class="libelle">
                        Montant à prélever
                      </div>
                      <span v-if="affichageRecapitulatif" class="sr-only">
                        {{ $filters.numberSpacedRgaa(item.resteAPayer) }}</span
                      >
                      <span
                        v-if="affichageRecapitulatif"
                        aria-hidden="true"
                        class="item base-color fw-bold item-rect"
                        >{{ $filters.numberSpaced(item.resteAPayer) }}</span
                      >
                      <Amount
                        v-if="affichageRepartitionMontant"
                        :id="'pai-05-' + dindex"
                        libelle="Montant à prélever"
                        :valeur="item.resteAPayer.toString()"
                        :initValeur="totalMontantAPayer.toString()"
                        :actif="true"
                        :compSupVal="compSupVal"
                        :messageCompSupVal="messageCompSupVal"
                        :item="item"
                        :idMontant="'resteAPayerRepMontant' + dindex"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="affichageRepartitionMontant"
                class="wrapper-beige mt-2"
              >
                <div class="fr-grid-row">
                  <div class="fr-col-6">
                    <img
                      src="../assets/img/money-euro-circle-fill.svg"
                      alt="icone du signe euro"
                    />
                  </div>
                  <div class="fr-col-3">
                    <div class="libelle">Montant provisionné</div>
                    <span class="sr-only">
                      {{ $filters.numberSpacedRgaa(montantProv) }}</span
                    >
                    <span
                      aria-hidden="true"
                      class="item fw-bold"
                      :class="{ 'error-color': showErrorRepMontants }"
                      >{{ $filters.numberSpaced(montantProv) }}</span
                    >
                  </div>
                  <div class="barre">/</div>
                  <div class="fr-col-3 arepartir">
                    <div class="libelle">Montant à répartir</div>
                    <span class="sr-only">
                      {{ $filters.numberSpacedRgaa(totalMontantAPayer) }}</span
                    >
                    <span
                      aria-hidden="true"
                      class="item base-color fw-bold pl-70"
                      >{{ $filters.numberSpaced(totalMontantAPayer) }}</span
                    >
                  </div>
                </div>
              </div>
              <div class="mt-50 d-inline-flex float-r">
                <button
                  id="pai-07"
                  class="fr-btn fr-btn--secondary mr-20 prs-btn"
                  title="Précédent"
                  aria-label="Précédent"
                  @click="previous()"
                >
                  <span class="pointer td-previous">Précédent</span>
                </button>
                <button
                  v-if="affichageRecapitulatif"
                  id="pai-08"
                  class="fr-btn pr-btn btn-action w-215"
                  title="Effectuer le paiement"
                  aria-label="Effectuer le paiement"
                  @click="effectuerPaiement()"
                >
                  <span class="pointer">Effectuer le paiement</span>
                </button>
                <button
                  v-if="affichageRepartitionMontant"
                  id="pai-08"
                  class="fr-btn pr-btn btn-confirmation w-225"
                  title="Confirmer la répartition"
                  aria-label="Confirmer la répartition"
                  @click="confirmerRepartition()"
                  :disabled="showErrorRepMontants || montantRepartitionZero"
                >
                  <span class="pointer">Confirmer la répartition</span>
                </button>
              </div>
            </div>
          </div>
          <div
            v-if="affichagePriseEnCompte && paiementOK"
            class="fr-col-7 paiementResultat"
          >
            <div class="ml-32">
              <div class="ordre-de-paiem ok">
                <img
                  id="loa_01"
                  src="../assets/img/rond-ok.svg"
                  alt="cercle avec une icone validé dedans "
                />
                <p class="paiementResultatTitre">
                  Votre ordre de paiement a bien été enregistré
                </p>
              </div>
              <br />
              <p class="les-ordres-de-pr">
                Le(s) ordre(s) de prélèvement validé(s), en cours de traitement,
                sera(ont) affiché(s)<br />sur la page Paiements de
                portailpro.gouv d'ici 24h à 48h.<br /><br />Le(s) prélèvement(s)
                interviendra(ont) à compter du
                {{
                  $filters.dateFormat(confirmationData.datePrelevementCommune)
                }}
              </p>
            </div>
          </div>
          <div
            v-if="affichagePriseEnCompte && !paiementOK"
            class="fr-col-7 paiementResultat"
          >
            <div class="ml-32">
              <div class="ordre-de-paiem ko">
                <img
                  id="loa_01"
                  src="../assets/img/rond-ko.svg"
                  alt="icone avec un cercle rouge et une croix dedans"
                />
                <p class="paiementResultatTitre">
                  Votre ordre de paiement n'a pas été enregistré
                </p>
              </div>
              <br />
              <p class="le-paiement-intgr">
                Le paiement intégré aux IHM de portailpro n’est éligible que
                pour les créances d’impôts d’État (hors AMR) de la DGFIP dans la
                version actuelle de portailpro.
              </p>
              <p class="veuillez-recommencer retour-paiement">
                Veuillez recommencer ultérieurement et contactez votre service
                des impôts si le problème perdure.<br /><br />
                <button
                  id="pai-09"
                  class="btn fr-btn fr-btn--primary btn-action color-pr fs-875 bg-pr mx-2"
                  title="Retour aux paiements"
                >
                  <router-link
                    :to="{ name: 'Paiements' }"
                    class="text-white"
                    aria-label="Cliquer ici pour retourner aux paiements"
                    title="Cliquer ici pour retourner aux paiements"
                  >
                    Retour aux paiements
                  </router-link>
                </button>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div v-if="affichageMontantAPayer" class="mt-40">
        <p class="fs-925 my-sm-">
          Ordre de prélèvement bancaires validés (exécutés ou en cours
          d'exécution)
        </p>
        <div class="fs-925">
          <table id="pai-10" class="w-100 detail-table padding-table">
            <caption>
              Ordre de prélèvement bancaires validés (exécutés ou en cours
              d'exécution)
            </caption>
            <tr class="bt-dark">
              <th scope="col">
                Date et heure de la validation de l'ordre de prélèvement
              </th>
              <th scope="col">Compte bancaire (IBAN)</th>
              <th scope="col">
                N° du certificat de prise en compte de l'orde de paiement
              </th>
              <th scope="col" class="text-right">Montant</th>
            </tr>

            <tr
              class="bg-white"
              v-for="(detail,
              dindex) in dataPaiementAmr.listeOrdrePrelevementEnregistres"
              :key="`detail-${dindex}`"
              :class="{ refused: (dindex + 1) % 2 == 0 }"
            >
              <td>
                {{
                  $filters.dateTimeFormat(
                    detail.dateHeureValidationOrdrePaiement
                  )
                }}
              </td>
              <td>{{ detail.ibanAnonymise }}</td>
              <td>{{ detail.numCertificat }}</td>
              <td class="text-right">
                <span class="sr-only">
                  {{ $filters.numberSpacedRgaa(montantOrdre) }}</span
                >
                <span aria-hidden="true" class="fw-bold">{{
                  $filters.numberSpaced(detail.montantOrdre)
                }}</span>
              </td>
            </tr>
          </table>
        </div>
        <p class="fs-925 my-sm-4">
          Paiements comptabilisés hors prélèvement bancaire
        </p>
        <div class="fs-925">
          <table id="pai-11" class="w-100 detail-table padding-table">
            <caption>
              Paiements comptabilisés hors prélèvement bancaire
            </caption>
            <tr class="bt-dark">
              <th scope="col">Date du paiement</th>
              <th scope="col">Moyen de paiement</th>
              <th scope="col" class="text-right">Montant payé</th>
            </tr>

            <tr
              class="bg-white"
              v-for="(detail, dindex) in dataPaiementAmr.factureAmr
                .listePaiementsHorsPrelevement"
              :key="`detail-${dindex}`"
              :class="{ refused: (dindex + 1) % 2 == 0 }"
            >
              <td>{{ $filters.dateFormat(detail.dateApurement) }}</td>
              <td>{{ detail.typeApurement }}</td>
              <td class="text-right">
                <span class="sr-only">
                  {{ $filters.numberSpacedRgaa(detail.montantRegle) }}</span
                >
                <span aria-hidden="true" class="fw-bold">{{
                  $filters.numberSpaced(detail.montantRegle)
                }}</span>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div v-if="affichagePriseEnCompte && paiementOK">
        <div class="fs-925">
          <p>
            Les références de votre(vos) ordre(s) de paiement validé(s) le
            {{
              $filters.dateTimeFormat(
                confirmationData.dateHeureValidationOrdrePaiementCommune
              )
            }}
            :
          </p>
          <table id="pai-12" class="w-100 detail-table padding-table">
            <caption>
              Paiements comptabilisés hors prélèvement bancaire
            </caption>
            <tr class="bt-dark">
              <th scope="col" class="first-col">
                N° du certificat de prise en compte<br />de l'ordre de paiement
              </th>
              <th scope="col">IBAN du compte bancaire</th>
              <th scope="col" class="col-rum">
                Référence Unique du Mandat (RUM)
              </th>
              <th scope="col" class="col-montant text-right">Montant</th>
            </tr>

            <tr
              v-for="(p,
              index) in confirmationData.listeOrdrePaiementEnregistres"
              class="bg-white"
              :key="index"
            >
              <td>{{ p.numCertificat }}</td>
              <td>{{ p.ibanAnonymise }}</td>
              <td>{{ p.rum }}</td>
              <td class="text-right">
                <span class="sr-only">
                  {{ $filters.numberSpacedRgaa(p.montantOrdre) }}</span
                >
                <span aria-hidden="true" class="fw-bold">{{
                  $filters.numberSpaced(p.montantOrdre)
                }}</span>
              </td>
            </tr>
          </table>
          <div class="fs-925 refcontest">
            <p>
              Ces références vous seront réclamées en cas de contestation. Nous
              vous conseillons de conserver ces informations.
            </p>
          </div>
          <div class="row">
            <div class="col-12 align-center">
              <button
                id="pai-13"
                class="btn fr-btn fr-btn--secondary color-pr fs-875 btn"
                type="button"
                aria-label="Cliquer ici pour imprimer les référence(s) de votre(vos) ordre(s)"
                @click="imprimer()"
                title="Cliquer ici pour imprimer les référence(s) de votre(vos) ordre(s)"
              >
                Imprimer
              </button>
              <router-link
                id="pai-14"
                :to="{ name: 'Paiements' }"
                class="btn fr-btn fr-btn--primary color-pr fs-875 bg-pr mx-2"
                aria-label="Cliquer ici pour retourner aux paiements"
                title="Cliquer ici pour retourner aux paiements"
              >
                Retour aux paiements
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <p class="text-center text-gray mt-40 pb-4 mt-test">
        Ces informations sont données à titre indicatif et ne sont pas
        nécessairement exhaustives.
      </p>
    </div>
  </div>
</template>

<script>
import { jsPDF } from 'jspdf';
require('jspdf-autotable');
import { getBase64Image } from '../utils/pdfUtils';
import SkipLinkMixin from '../mixins/skip-link-mixin';
import AlertDesignSystem from '../components/AlertDesignSystem';
import Amount from '../shared/Amount';
import ErreurPaiementImpot from '../components/ErreurPaiementImpot';
import Stepper from '../shared/Stepper';
import Synthese from '../shared/Synthese';

import { mapGetters, mapActions } from 'vuex';
import { initierPaiementCreanceAmr } from '../services/PaiementsService';
import { dateFormat, dateTimeFormat, numberSpaced } from '../filters';
import {
  AMOUNT_EVENT_VALUE,
  AMOUNT_EVENT_INIT,
  STEPPER_EVENT_STEP_INDEX,
  STEPPER_EVENT_STEP_PRECEDENT,
  STEPPER_EVENT_STEP_SUIVANT,
  POPUP_IS_OPEN,
  POPUP_IS_CONFIRM_DATA,
  SOURCE_PAGE_PAIEMENT_AMR
} from '../constantes';
import Info from '../shared/Info.vue';
import { isFeatureActive } from '../utils/methodsUtils';
export const dataStepsMonoCompte = [
  {
    libelle: 'Montant à payer',
    num: '1',
    index: 0,
    visible: true
  },
  {
    libelle: 'Récapitulatif du paiement',
    num: '2',
    index: 1,
    visible: false
  },
  {
    libelle: "Prise en compte de l'ordre de paiement",
    num: '3',
    index: 2,
    visible: false
  }
];
export const dataStepsMultiCompteSansRepartition = [
  {
    libelle: 'Montant à payer',
    num: '1',
    index: 0,
    visible: true
  },
  {
    libelle: 'Sélection du compte bancaire',
    num: '2',
    index: 1,
    visible: false
  },
  {
    libelle: 'Récapitulatif du paiement',
    num: '3',
    index: 2,
    visible: false
  },
  {
    libelle: "Prise en compte de l'ordre de paiement",
    num: '4',
    index: 3,
    visible: false
  }
];
export const dataStepsMultiCompteAvecRepartition = [
  {
    libelle: 'Montant à payer',
    num: '1',
    index: 0,
    visible: true
  },
  {
    libelle: 'Sélection du compte bancaire',
    num: '2',
    index: 1,
    visible: false
  },
  {
    libelle: 'Répartition du montant',
    num: '3',
    index: 2,
    visible: false
  },
  {
    libelle: 'Récapitulatif du paiement',
    num: '4',
    index: 3,
    visible: false
  },
  {
    libelle: "Prise en compte de l'ordre de paiement",
    num: '5',
    index: 4,
    visible: false
  }
];
export default {
  components: {
    AlertDesignSystem,
    Amount,
    Stepper,
    Synthese,
    ErreurPaiementImpot,
    Info
  },
  mixins: [SkipLinkMixin],
  data() {
    return {
      creance: Object,
      paiementOK: false,
      confirmationData: {},
      dataSteps: dataStepsMonoCompte,
      dataPaiementAmr: null,
      montant: { valid: true, value: 0 },
      compSupVal: true,
      messageCompSupVal:
        'Merci de saisir un montant à payer inférieur ou égal au montant restant à payer',
      erreur: false,
      erreurMessage:
        'Le paiement intégré aux IHM de portailpro est indisponible',
      titreErreurMessage: 'Attention',
      titreErreurMessage2: 'Votre avis de mise en recouvrement est déjà soldé',
      titreErreurMessage3:
        "Aucun avis de mise en recouvrement n'a été trouvé pour cette créance",
      erreurMessageRep: '',
      erreurTitleRepMontants: 'Erreur(s) de montant(s)',
      erreurMessageRepMontantsEgale:
        'La somme des montants à prélever doit être égale au montant à répartir.',
      erreurMessageRepMontantsZero:
        'le montant saisi doit être un nombre strictement positif avec au plus deux décimales',

      //Message générique affiché au cas ou l'utilsateur a sélectionné plusieurs comptes bancaires ou aucun compte bancaire
      erreurMessageCompte:
        'Merci de sélectionner entre 1 et 3 comptes bancaires',
      erreurTitleCompte: 'Attention',
      disabledConfirmation: true,
      showErrorMultiCompte: false,

      // Etape : Répartition des montants sur les mandats
      disabledRepartition: true,
      montantRepartitionZero: true,
      showErrorRepMontants: false,
      montantProv: 0,
      totalMontantAPayer: 0,

      // l'index du dataStep d'un écran est variable en fonction du contexte
      indexDataStepMontantAPayer: 0,
      indexDataStepSelectionCompte: 1,
      indexDataStepRepartitionMontant: 2,
      indexDataStepRecapitulatif: 3,
      indexDataStepPriseEnCompte: 4,

      disableResteAPayerCreance: true,
      // clé pour uploader les montants mis à jour
      testIndex: 0,
      // cas multi mandats : on considère que la liste des mandats est systématiquement maj de l'étape 2 à l'étape 3
      mandatsMaj: true
    };
  },
  computed: {
    ...mapGetters(['siren']),
    ...mapGetters('paiement', ['creanceEnCours']),
    conteneurSyntheseClasses() {
      return {
        'fr-grid-row': true,
        'fr-grid-row--center': true,
        'synthese-pr-conteneur': this.affichagePriseEnCompte
      };
    },
    affichageMontantAPayer() {
      return (
        this.indexDataStepMontantAPayer != -1 &&
        this.dataSteps[this.indexDataStepMontantAPayer] &&
        this.dataSteps[this.indexDataStepMontantAPayer].visible
      );
    },
    affichageSelectionCompte() {
      return (
        this.indexDataStepSelectionCompte != -1 &&
        this.dataSteps[this.indexDataStepSelectionCompte] &&
        this.dataSteps[this.indexDataStepSelectionCompte].visible
      );
    },
    affichageRepartitionMontant() {
      return (
        this.indexDataStepRepartitionMontant != -1 &&
        this.dataSteps[this.indexDataStepRepartitionMontant] &&
        this.dataSteps[this.indexDataStepRepartitionMontant].visible
      );
    },
    affichageRecapitulatif() {
      return (
        this.indexDataStepRecapitulatif != -1 &&
        this.dataSteps[this.indexDataStepRecapitulatif] &&
        this.dataSteps[this.indexDataStepRecapitulatif].visible
      );
    },
    affichagePriseEnCompte() {
      return (
        this.indexDataStepPriseEnCompte != -1 &&
        this.dataSteps[this.indexDataStepPriseEnCompte] &&
        this.dataSteps[this.indexDataStepPriseEnCompte].visible
      );
    }
  },

  methods: {
    ...mapActions(['setRouteData']),
    getAmr() {
      let i = 0;
      while (i < this.creance.detailCreanceImpots.debits[0].amr.length) {
        let amrObj = this.creance.detailCreanceImpots.debits[0].amr[i];
        if (amrObj.numeroAmr) {
          return amrObj.numeroAmr;
        }
        i++;
      }
    },
    goPrevious(event) {
      if (event) {
        event.preventDefault();
      }
      if (
        localStorage.getItem('showDetailBreadcrumbs') &&
        localStorage.getItem('showDetailBreadcrumbs') === 'true'
      ) {
        const routeData = {
          source: SOURCE_PAGE_PAIEMENT_AMR,
          retourDetail: true,
          item: this.creance
        };
        this.setRouteDataAndPush(routeData, 'Détail paiement');
      } else {
        const routeData = {
          source: SOURCE_PAGE_PAIEMENT_AMR,
          retourDetail: true
        };
        this.setRouteDataAndPush(routeData, 'Paiements');
      }
    },
    previous() {
      this.emitter.emit(STEPPER_EVENT_STEP_PRECEDENT);
      this.mandatsMaj = false;
    },
    poursuivre() {
      if (this.dataPaiementAmr.listeMandatsB2BActifSelected !== undefined) {
        if (this.dataPaiementAmr.listeMandatsB2BActifSelected.length === 1) {
          this.dataPaiementAmr.listeMandatsB2BActifSelected[0].resteAPayer = this.totalMontantAPayer;
        }
      }
      this.next();
    },
    next() {
      this.emitter.emit(STEPPER_EVENT_STEP_SUIVANT);
    },
    // ajouter les comptes sélectionnnés

    // affecter la somme des montants

    initierDataSteps() {
      if (this.dataPaiementAmr) {
        if (this.dataPaiementAmr.listeMandatsB2BActif.length > 1) {
          this.dataSteps = dataStepsMultiCompteSansRepartition;
          this.indexDataStepMontantAPayer = 0;
          this.indexDataStepSelectionCompte = 1;
          this.indexDataStepRecapitulatif = 2;
          this.indexDataStepPriseEnCompte = 3;
          // On n'affiche pas l'étape répartition du montant tant que plusieurs comptes n'ont pas été sélectionnés
          this.indexDataStepRepartitionMontant = -1;
        } else {
          this.dataSteps = dataStepsMonoCompte;
          this.indexDataStepMontantAPayer = 0;
          this.indexDataStepRecapitulatif = 1;
          this.indexDataStepPriseEnCompte = 2;
          // On n'affiche pas les étapes répartition du montant et Sélection du compte
          this.indexDataStepSelectionCompte = -1;
          this.indexDataStepRepartitionMontant = -1;

          //Cas mono mandat
          if (this.dataPaiementAmr.listeMandatsB2BActif.length === 1) {
            this.dataPaiementAmr.listeMandatsB2BActifSelected = [];
            this.dataPaiementAmr.listeMandatsB2BActifSelected.push(
              this.dataPaiementAmr.listeMandatsB2BActif[0]
            );
          }
        }
        if (this.dataSteps) {
          this.dataSteps.forEach((el) => {
            el.visible = false;
          });
          if (this.dataSteps[0] != undefined) {
            this.dataSteps[0].visible = true;
          }
        }
      }
    },

    async initierPaiementCreanceAmr() {
      try {
        let siren = '',
          numeroAmr = '';
        if (
          isFeatureActive('PCR2-7477') &&
          this.creance.bouchon &&
          this.creance.bouchon.siren
        ) {
          siren = this.creance.bouchon.siren;
        } else {
          siren = this.siren;
        }
        if (
          this.creance.detailCreanceImpots &&
          this.creance.detailCreanceImpots.debits &&
          this.creance.detailCreanceImpots.debits.length > 0 &&
          this.creance.detailCreanceImpots.debits[0].amr &&
          this.creance.detailCreanceImpots.debits[0].amr.length > 0
        ) {
          numeroAmr = this.getAmr();
        }
        const resp = await initierPaiementCreanceAmr(
          siren,
          this.creance.contexte.codeOBF,
          numeroAmr,
          this.creance.nic,
          this.creance.numeroSieSages
        );
        const data = await resp.data;
        let resultat = await data.resultat;

        //erreurs?
        const errInfo = resp.data.infos;

        // si le resultat est null on affiche la page d'erreur avec comme message le contenu de infoMessage
        if (resultat === null) {
          this.erreur = true;
        }
        if (errInfo && errInfo.length > 0) {
          if (resultat === null) {
            if (errInfo[0].infoCode == '40021') {
              this.titreErreurMessage = this.titreErreurMessage2;
            }
            if (errInfo[0].infoCode == '40020') {
              this.titreErreurMessage = this.titreErreurMessage3;
            }
            this.erreurMessage = errInfo[0].infoMessage;
          }
        }
        this.dataPaiementAmr = resultat;
      } catch (error) {
        this.erreur = true;
      }
    },
    formatDateRange(dateInput) {
      const startDate =
        dateInput.slice(0, 2) +
        '/' +
        dateInput.slice(2, 4) +
        '/' +
        dateInput.slice(4, 8);
      const endDate =
        dateInput.slice(11, 13) +
        '/' +
        dateInput.slice(13, 15) +
        '/' +
        dateInput.slice(15);
      return startDate + ' - ' + endDate;
    },
    imprimer() {
      const x = require('@/assets/img/fr-logo.png');
      const y = require('@/assets/img/dgfip-logo.png');

      const img1 = getBase64Image(x);
      const img2 = getBase64Image(y);

      Promise.all([img1, img2]).then((values) => {
        var doc = new jsPDF();

        const docY = doc.internal.pageSize.height;

        doc.addImage(values[0], 'PNG', 1, 1, 32, 32);
        doc.addImage(values[1], 'PNG', 160, 1, 50, 30);
        doc.setFont('Marianne');
        doc.setFontSize(20);

        doc.text(
          30,
          65,
          "Certificat de prise en compte de l'ordre de paiement"
        );
        doc.text(50, 75, 'de l’avis de mise en recouvrement');

        doc.setFontSize(12);
        doc.text(
          13,
          90,
          'Caractéristiques de l’avis de mise en recouvrement :'
        );

        doc.setFontSize(11);
        doc.circle(18, 99, 1, 'F');
        doc.text(21, 100, 'Numéro : ' + 'this.confirmationData.numeroAmr');

        doc.circle(18, 104, 1, 'F');
        doc.text(
          21,
          105,
          'Montant dû : ' +
            'numberSpaced(this.confirmationData.resteAPayerAvantValidationOrdreAmr)'
        );

        doc.circle(18, 109, 1, 'F');
        doc.text(
          21,
          110,
          'Montant total de l’ordre de paiement : ' +
            'numberSpaced(this.confirmationData.montantTotalPaiement)'
        );

        doc.circle(18, 114, 1, 'F');
        doc.text(
          21,
          115,
          'Montant restant dû : ' +
            'numberSpaced(this.confirmationData.resteAPayerApresValidationOrdreAmr)'
        );

        doc.setFontSize(12);
        doc.text(
          13,
          135,
          'Caractéristiques des créances associées à l’avis de mise en recouvrement : '
        );

        const l = this.confirmationData.ventilationPaiementsParCreance.length;
        let lastY = 135;

        doc.setFontSize(11);

        for (var j = 0; j < l; j++) {
          lastY = lastY + 5;
          doc.circle(18, lastY - 1, 1, 'F');
          doc.text(
            21,
            lastY,
            'Éléments à payer : ' +
              this.confirmationData.ventilationPaiementsParCreance[j]
                .detailElementAPayer
          );

          lastY = lastY + 5;
          doc.circle(18, lastY - 1, 1, 'F');
          doc.text(
            21,
            lastY,
            'Période : ' +
              this.formatDateRange(
                this.confirmationData.ventilationPaiementsParCreance[j].periode
              )
          );

          lastY = lastY + 5;
          doc.circle(18, lastY - 1, 1, 'F');
          doc.text(
            21,
            lastY,
            'Montant dû : ' +
              numberSpaced(
                this.confirmationData.ventilationPaiementsParCreance[j]
                  .soldeDuCreance
              )
          );

          lastY = lastY + 5;
          doc.circle(18, lastY - 1, 1, 'F');
          doc.text(
            21,
            lastY,
            'Montant réglé sur cette créance : ' +
              numberSpaced(
                this.confirmationData.ventilationPaiementsParCreance[j]
                  .montantVentilationPaiementCreance
              )
          );

          lastY = lastY + 5;
          doc.circle(18, lastY - 1, 1, 'F');
          doc.text(
            21,
            lastY,
            'Montant restant dû sur cette créance : ' +
              numberSpaced(
                this.confirmationData.ventilationPaiementsParCreance[j]
                  .creanceResteAPayerApresValidationOrdreAmr
              )
          );

          lastY = lastY + 5;

          if (docY - lastY < 40) {
            doc.addPage();
            lastY = 20;
          }
        }

        doc.text(
          13,
          lastY + 5,
          'Les références de votre(vos) ordre(s) de paiement validé(s) le ' +
            dateTimeFormat(
              this.confirmationData.dateHeureValidationOrdrePaiementCommune,
              true
            )
        );

        // Supply data via script

        var body = [];
        let ll = this.confirmationData.listeOrdrePaiementEnregistres.length;
        for (let i = 0; i < ll; i++) {
          var tr = [];
          tr.push(
            this.confirmationData.listeOrdrePaiementEnregistres[i].numCertificat
          );
          tr.push(
            this.confirmationData.listeOrdrePaiementEnregistres[i].ibanAnonymise
          );
          tr.push(this.confirmationData.listeOrdrePaiementEnregistres[i].rum);
          tr.push(
            numberSpaced(
              this.confirmationData.listeOrdrePaiementEnregistres[i]
                .montantOrdre
            )
          );
          body.push(tr);
        }
        // generate auto table with body

        doc.setLineWidth(2);

        if (docY - lastY < 40) {
          doc.addPage();
          lastY = 20;
        } else {
          lastY = lastY + 10;
        }

        doc.autoTable({
          headStyles: {
            fontStyle: 'bold',
            fontSize: 8,
            fillColor: '#eeeeee',
            textColor: '#000000',
            lineWidth: { top: 0, right: 0, bottom: 0.8, left: 0 }
          },
          columnStyles: {
            0: {
              cellWidth: 50
            },
            3: {
              fontStyle: 'bold',
              cellWidth: 20,
              halign: 'right'
            }
          },
          bodyStyles: {
            fontSize: 8,
            lineWidth: { top: 0, right: 0, bottom: 0.1, left: 0 }
          },
          head: [
            [
              "N° du certificat de prise en compte de l'ordre de paiement",
              'IBAN du compte bancaire',
              'Référence Unique du Mandat (RUM)',
              'Montant'
            ]
          ],
          body: body,
          startY: lastY
        });
        let finalY = doc.lastAutoTable.finalY; // The y position on the page

        if (docY - finalY < 40) {
          doc.addPage();
          finalY = 0;
        }

        doc.setFontSize(12);
        doc.text(
          13,
          finalY + 10,
          'Le(s) prélèvement(s) interviendra(ont) à compter du ' +
            dateFormat(this.confirmationData.datePrelevementCommune)
        );

        doc.text(
          13,
          finalY + 20,
          'Ces références vous seront réclamées en cas de contestation. Nous vous conseillons de conserver ce'
        );

        doc.text(13, finalY + 25, 'document.');

        doc.text(
          13,
          finalY + 35,
          'Les détails relatifs à vos paiements sont affichés sur la page Paiement de portailpro.gouv 24 à 48 heures'
        );

        doc.text(
          13,
          finalY + 40,
          "après la validation de l'ordre de paiement."
        );

        doc.save('certificat-ordres-paiements-amr.pdf');
      });
    },
    updateMontant(result) {
      // étape 1 : à payer créances amr
      if (
        result.idMontant &&
        result.idMontant.startsWith('resteAPayerCreance-')
      ) {
        let index = result.idMontant.split('-')[1];
        let rightCreance = this.dataPaiementAmr.listeCreanceAmr[index];
        // si la creance existe et s'il n'y a pas d'erreur
        if (
          rightCreance &&
          (result.montant.valid || result.montant.value === '')
        ) {
          rightCreance.montantVentilationPaiementCreance =
            result.montant.value === '' ? 0 : Number(result.montant.value);
          rightCreance.resteApayer =
            rightCreance.montantVentilationPaiementCreance > 0
              ? rightCreance.soldeDuCreance -
                rightCreance.montantVentilationPaiementCreance
              : rightCreance.soldeDuCreance;
          this.testIndex++;
        }
        //Pour griser le bouton Poursuivre ou pas
        let numberOfZeroValue = 0;
        this.totalMontantAPayer = 0;
        this.dataPaiementAmr.listeCreanceAmr.forEach((el) => {
          //total montant à payer pour l'étape de répartition des montants
          this.totalMontantAPayer =
            Number(this.totalMontantAPayer) +
            Number(el.montantVentilationPaiementCreance);
          if (el.montantVentilationPaiementCreance == 0) {
            numberOfZeroValue++;
          }
        });
        if (
          this.dataPaiementAmr.listeCreanceAmr.length == numberOfZeroValue ||
          (!result.montant.valid && result.montant.value != 0)
        ) {
          this.disableResteAPayerCreance = true;
        } else {
          this.disableResteAPayerCreance = false;

          // 300 ms le temps que le bouton se réactive et devient dispo depuis le DOM
          /* var el = document.querySelector('#pai-06');
          setTimeout(() => {
            el.focus();
          }, 300);*/
        }
      } else if (
        // étape répartition du(des) montant(s) sur les mandats
        result.idMontant &&
        result.idMontant.startsWith('resteAPayerRepMontant')
      ) {
        if (
          this.dataPaiementAmr &&
          this.dataPaiementAmr.listeMandatsB2BActifSelected
        ) {
          let stop = false;
          this.dataPaiementAmr.listeMandatsB2BActifSelected.forEach((elm) => {
            if (elm.compteId === result.item.compteId) {
              elm.resteAPayer = result.montant.value;
              if (elm.isInitialValue && Number(result.montant.value) != 0) {
                elm.isInitialValue = false;
              } else if (Number(result.montant.value) === 0) {
                elm.isInitialValue = true;
              }
            }
            if (!Number(elm.resteAPayer)) {
              stop = true;
            }
          });
          if (stop) {
            this.montantRepartitionZero = true;
          } else {
            this.montantRepartitionZero = false;
          }
          this.setMontantProv();
        }
      }
    },
    switchSelectCompte(item) {
      this.mandatsMaj = true;
      item.compteEnabled = !item.compteEnabled;
      let index = 0;
      this.dataPaiementAmr.listeMandatsB2BActif.forEach((element) => {
        if (element.compteEnabled) {
          index++;
        }
      });
      if (index > 3) {
        this.showErrorMultiCompte = true;
        this.disabledConfirmation = true;
      } else if (index === 0) {
        this.disabledConfirmation = true;
      } else {
        this.showErrorMultiCompte = false;
        this.disabledConfirmation = false;
      }
    },
    //Met à jour dataSteps en cas de sélection de plusieurs comptes
    // ( à appeler dans l'écran de sélection de compte si plusieurs comptes sont sélectionnés)
    handleSelectionMultipleCompte() {
      this.dataPaiementAmr.listeMandatsB2BActifSelected = [];
      let index = 0;
      this.dataPaiementAmr.listeMandatsB2BActif.forEach((element) => {
        if (element.compteEnabled) {
          //si la liste des mandats sélectionnés est maj, à l'étape 3 on initialise les montants à 0 sinon on affiche la valeur renseignée la 1ère fois à l'étape 3 (avant de cliquer sur precedent)
          element.resteAPayer = this.mandatsMaj
            ? 0
            : element.resteAPayer
            ? element.resteAPayer
            : 0;
          element.compteId = 'compte-' + index;
          element.isInitialValue = true;
          this.dataPaiementAmr.listeMandatsB2BActifSelected.push(element);
          index++;
        }
      });
      const comptesSelected = this.dataPaiementAmr.listeMandatsB2BActifSelected
        .length;
      if (comptesSelected > 1 && comptesSelected < 4) {
        this.dataSteps = dataStepsMultiCompteAvecRepartition;
        this.indexDataStepMontantAPayer = 0;
        this.indexDataStepSelectionCompte = 1;
        this.indexDataStepRepartitionMontant = 2;
        this.indexDataStepRecapitulatif = 3;
        this.indexDataStepPriseEnCompte = 4;
        this.next();
        this.showErrorMultiCompte = false;
        this.disabledConfirmation = false;
      } else if (comptesSelected === 1) {
        this.dataPaiementAmr.listeMandatsB2BActifSelected[0].resteAPayer = this.totalMontantAPayer;
        this.dataSteps = dataStepsMultiCompteSansRepartition;
        this.indexDataStepMontantAPayer = 0;
        this.indexDataStepSelectionCompte = 1;
        this.indexDataStepRepartitionMontant = -1;
        this.indexDataStepRecapitulatif = 2;
        this.indexDataStepPriseEnCompte = 3;
        this.next();
      } else {
        this.showErrorMultiCompte = true;
        this.disabledConfirmation = true;
      }
    },
    setMontantProv() {
      let montantProvisionne = this.dataPaiementAmr.listeMandatsB2BActifSelected.reduce(
        (accumulator, element) => {
          return accumulator + Number(element.resteAPayer);
        },
        0
      );
      if (Number(montantProvisionne)) {
        this.montantProv = montantProvisionne;
      } else {
        this.montantProv = 0;
      }
      let numberOfInitialValue = 0;
      this.dataPaiementAmr.listeMandatsB2BActifSelected.forEach((elm) => {
        if (elm.isInitialValue) {
          numberOfInitialValue++;
        }
      });
      if (this.montantProv > this.totalMontantAPayer) {
        this.erreurMessageRep = this.erreurMessageRepMontantsEgale;
        this.showErrorRepMontants = true;
        this.disabledRepartition = true;
      } else if (this.montantRepartitionZero && !numberOfInitialValue) {
        this.erreurMessageRep = this.erreurMessageRepMontantsZero;
        this.showErrorRepMontants = true;
        this.disabledRepartition = true;
      } else if (
        this.montantProv < this.totalMontantAPayer &&
        !numberOfInitialValue
      ) {
        this.erreurMessageRep = this.erreurMessageRepMontantsEgale;
        this.showErrorRepMontants = true;
        this.disabledRepartition = true;
      } else {
        this.showErrorRepMontants = false;
        this.disabledRepartition = false;
      }
    },
    confirmerRepartition() {
      if (this.montantProv != this.totalMontantAPayer) {
        this.showErrorRepMontants = true;
      } else {
        this.showErrorRepMontants = false;
        this.next();
      }
    },
    effectuerPaiement() {
      let listeOrdrePaiement_ = [];
      let siren = '';
      if (
        isFeatureActive('PCR2-7477') &&
        this.creance &&
        this.creance.bouchon &&
        this.creance.bouchon.siren
      ) {
        siren = this.creance.bouchon.siren;
      } else {
        siren = this.siren;
      }
      this.dataPaiementAmr.listeMandatsB2BActifSelected.forEach((element) => {
        if (element.resteAPayer != 0) {
          listeOrdrePaiement_.push({
            montantOrdre: element.resteAPayer,
            rum: element.rum
          });
        }
      });

      this.emitter.emit(POPUP_IS_OPEN, {
        siren: siren,
        nic: this.creance.nic,
        typeCreance: 'DGFIP_IMPOT_ETAT_AMR',
        codeRedevabilite: this.creance.contexte.codeOBF,
        numeroAmr: this.getAmr(),
        sieSagesOrigine: this.creance.numeroSieSages,
        montantTotalPaiement: this.totalMontantAPayer,
        listeOrdrePaiement: listeOrdrePaiement_,
        listeCreanceAmr: this.dataPaiementAmr.listeCreanceAmr,
        isAMR: true
      });
    },
    setRouteDataAndPush(routeData, routeName) {
      this.setRouteData(routeData)
        .then(() => {
          this.$router.push({
            name: routeName
          });
        })
        .catch((error) => console.error(error));
    }
  },

  async mounted() {
    this.creance = this.creanceEnCours;
    await this.initierPaiementCreanceAmr();
    this.initierDataSteps();

    //initialiser les champs montants
    if (this.dataPaiementAmr.listeCreanceAmr) {
      this.dataPaiementAmr.listeCreanceAmr.forEach((el) => {
        el.montantVentilationPaiementCreance = el.soldeDuCreance;
        el.resteApayer = el.montantVentilationPaiementCreance
          ? el.soldeDuCreance - el.montantVentilationPaiementCreance
          : el.soldeDuCreance;
      });
    }
  },

  created() {
    this.emitter.on(STEPPER_EVENT_STEP_INDEX, (stepIndex) => {
      this.dataSteps.forEach((el) => {
        el.visible = false;
      });
      if (this.dataSteps[stepIndex] != undefined) {
        this.dataSteps[stepIndex].visible = true;
      }
    });
    // Composant Amount
    this.emitter.on(AMOUNT_EVENT_VALUE, (result) => {
      this.updateMontant(result);
    });

    this.emitter.on(AMOUNT_EVENT_INIT, (result) => {
      this.updateMontant(result);
    });

    this.emitter.on(POPUP_IS_CONFIRM_DATA, (result) => {
      if (result) {
        this.paiementOK = true;
        this.confirmationData = result;
      }
      this.next();
      this.next();
    });
  },
  beforeUnmount() {
    this.emitter.off(STEPPER_EVENT_STEP_SUIVANT);
    this.emitter.off(POPUP_IS_CONFIRM_DATA);
  }
};
</script>

<style lang="scss" scoped>
@import '../styles/_tables.scss';

.paiementResultatTitre {
  font-size: 1em;
  line-height: 2.5rem !important;
}

.refcontest {
  margin-top: 1em;
  margin-bottom: 1em;
}

table {
  display: block !important;
}

dt {
  font-weight: 400;
}
.table-detail-impots {
  border-bottom: 4px solid #f8f8f8;
}
a:focus {
  border-radius: 0;
}

.a-info {
  box-shadow: none;
}

.fr-fi-information-line:hover {
  cursor: pointer;
  transform: scale(1.1);
  color: blue;
}

p.list {
  margin-bottom: 0.95rem;
}

.action-urssaf {
  padding: 0.875rem;
}

.label-siret {
  font-style: italic;
  font-weight: 700;
}
.w-montant {
  width: 15% !important;
}
.w-20 {
  width: 20%;
}
.btn-tout-voir {
  background-color: white;
  border: 1px solid $base-color;
  color: $base-color;
}
.border-btm {
  border-bottom: 1px solid #ddd;
}
.margin-sub {
  margin-left: 5px;
  margin-top: -10px;
}
.pb-10 {
  padding-bottom: 10px;
}
.mt-10 {
  margin-top: 10px;
}
.mt-30 {
  margin-top: 30px;
}
.mt-40 {
  margin-top: 40px;
}
.mt-test {
  margin-top: 100px;
}

.base-color {
  color: $base-color;
}
.mt--7 {
  margin-top: -7px;
}
.libelle {
  color: #6a6a6a;
  font-size: 0.875rem;
  width: 100%;
}
.item {
  color: #1e1e1e;
  font-size: 1rem;
  width: 100%;
}
.mt-0625 {
  margin-top: 0.625rem;
}
.mt-2 {
  margin-top: 2rem !important;
}
.mt-50 {
  margin-top: 50px;
}
.mt-60 {
  margin-top: 60px;
}
.mt-80 {
  margin-top: 80px;
}
.mr-30 {
  margin-right: 30px;
}
.mr-20 {
  margin-right: 20px;
}
.float-r {
  float: right;
}
.line {
  width: 1px;
  height: 80px;
  margin: 5px 6px;
  border: solid 1px #cecece;
  border-style: dashed;
}
.oval {
  width: 13px;
  height: 13px;
  border-radius: 8.5px;
  border-style: solid;
  color: #cecece;
}
.fs-1875 {
  font-size: 1.875rem;
}
.pr-btn {
  /*background: $base-color;
  color: white;*/
  border: 0;
  padding: 0 18px;
  width: 129px;
  height: 40px;
}
.w-215 {
  width: 215px !important;
}
.w-225 {
  width: 225px !important;
}
.prs-btn {
  padding: 0 18px;
  width: 125px;
  height: 40px;
}
.float-r {
  float: right;
}
.td-action {
  font-size: 16px;
  margin-top: 4px;
  /* color: white;*/
}
.td-previous {
  font-size: 16px;
  margin-top: 4px;
  color: $base-color;
}
.title-bleu-fr {
  font-weight: bold;
  line-height: 1.27rem;
  font-size: 22px;
}
.bleu-fr-400 {
  color: $bleu-fr-400;
}
.ml-32 {
  margin-left: 2rem;
}
.wrapper-fr {
  background: #fefefe;
  font-family: 'Marianne';
  border: 1px solid #dddddd;
  padding-top: 15px !important;
  padding: 20px;
  position: relative;
  border-left-color: $bleu-fr-400;
  border-left-width: thick;
  margin-top: 1rem;
}
.wrapper-cb {
  background: #fefefe;
  font-family: 'Marianne';
  border: 1px solid #dddddd;
  padding-top: 15px !important;
  padding: 20px;
  position: relative;
  border-left-color: $base-color !important;
  border-left-width: thick;
  width: 360px;
}
.wrapper-beige {
  background: #f9f8f6;
  font-family: 'Marianne';
  border: 1px solid #f9f8f6;
  padding: 6px 31px 8px 20px;
  position: relative;

  .libelle {
    text-align: right;
  }
  .item {
    display: block;
    text-align: right;
  }

  .barre {
    width: 3%;
    margin-top: 1.1rem;
    text-align: right;
  }
  .arepartir {
    width: 22%;
    max-width: 22%;
  }
}
.base-color {
  color: $base-color;
}
.item-rect {
  background-color: #f2f2f9;
  border-radius: 12.5px;
  padding: 3px 11px 6px;
}
.item-txt {
  font-size: 1rem;
  color: #383838;
}

.ordre-de-paiem {
  width: 404px;
  height: 80px;

  font-family: Marianne;
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  color: #1e1e1e;

  &.ok {
    margin: 30px 278px 0px 100px;
  }
  &.ko {
    margin: 30px 278px 25.6px 100px;
  }
}

.synthese-pr-conteneur {
  .ordre-de-paiem {
    &.ok,
    &.ko {
      margin: 30px auto 25.6px;
    }
  }
}

.les-ordres-de-pr {
  width: 649px;
  height: 96px;
  margin: 60px 153px 20px 25.5px;
  font-family: Marianne;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #383838;
}

.le-paiement-intgr {
  width: 483px;
  height: 72px;
  margin: 60px 238px 40px 50px;
  font-family: Marianne;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #383838;
}

.veuillez-recommencer {
  width: 483px;
  height: 48px;
  margin: 40px 238px 19.4px 50px;
  font-family: Marianne;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #383838;
}

.retour-paiement {
  margin-bottom: 5em;
}
.align-center {
  text-align: center;
}

.first-col {
  width: 25%;
}
.col-rum {
  width: 25%;
}
.col-montant {
  width: 15%;
}

.btn:focus,
.fr-btn:focus {
  box-shadow: inset 0 0 0 2px #2a7ffe !important ;
}

.alert {
  max-width: 700px;
  margin-top: 60px;
  text-align: left !important;
  display: inline-block;
}

.ml-20 {
  margin-left: 20%;
}
.mt-375 {
  margin-top: 3.75rem;
}
.mb-1875 {
  margin-bottom: 1.875rem;
}
.w-210 {
  width: 210px;
}
.pl-95 {
  padding-left: 95px;
}
.pl-80 {
  padding-left: 80px;
}
.pl-20 {
  padding-left: 20px;
}
.mt-20 {
  margin-top: 20px;
}
.error-color {
  color: #b60000;
}
.btn-confirmation {
  display: inline-flex;
  vertical-align: initial;
}
#pai-06 {
  padding-left: 1.5rem;
}
button:disabled span.pointer {
  cursor: not-allowed;
}
#pai-09:hover {
  background-color: #1212ff !important;
}
</style>
